.image {
    width: 100%;
    height: 100%;
    box-shadow: 5px 10px 40px 10px;
    color: black;
    overflow: hidden;
    transition: var(--transition);
}

.image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: var(--transition);
    filter: grayscale(100%)


}

div.image:hover img{
    filter: grayscale(0);
    box-shadow: 6px 11px 42px 11px;
}



@media (orientation:landscape){
    .image:hover{
        transition: var(--transition);
        scale:1.05;
    }

    div.image:hover img{
        scale:1.05;
    }
}


@media (orientation:portrait){
    .image:hover{
        transition: var(--transition);
    }

    div.image:hover img{
        scale:1.1;
    }
}


