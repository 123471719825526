:root{
  --primary-color: #00db30;
  --primary-color-darker: #00a123;
  --primary-color-darkest: #006917;
  --primary-color-lighter:#00ff37;

  --transition: all ease-in-out 0.6s;
  --transition-fast: all ease-in-out 0.3s;
  --scroll-background:#1f1f1f;
  --scroll-thumb: #0f0f0f;
}

html{
  margin:0;
  overflow: hidden;
}

body {
  margin: 0;
  font-family:'Segoe UI Light', sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  height:100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar{
  width: 12px;
}

body::-webkit-scrollbar-thumb{
  background:var(--scroll-thumb);
  border: 2px solid var(--scroll-background);
  border-radius: 10px;
}

body::-webkit-scrollbar-track{
  background:var(--scroll-background);
}

@media (orientation:portrait){
  body::-webkit-scrollbar{
    width: 6px;
  }
  body::-webkit-scrollbar-thumb{
    border: 1px solid var(--scroll-background);
    border-radius: 5px;
  }
}

a{
  color: var(--primary-color-darker);
  text-decoration: none;
  transition: var(--transition);
}

a:hover{
  color:var(--primary-color-lighter);
}